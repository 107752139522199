import { autoserialize, autoserializeAs } from 'cerialize';

import { BaseDTO, IBaseDTO } from './base-dto';
import { OwnerType } from './owner';

export enum CampaignState {
  ACTIVE = 'active',
  DISABLED = 'disabled',
}

export class Campaign extends BaseDTO implements ICampaign {
  @autoserialize id?: string;
  @autoserializeAs('created_at') createdAt?: string | null;
  @autoserializeAs('updated_at') updatedAt?: string | null;
  @autoserializeAs('discarded_at') discardedAt?: string | null;
  @autoserialize override name: string;
  @autoserialize state: CampaignState;
  @autoserialize qr_code: boolean;
  @autoserialize url: boolean;
  @autoserialize phone: boolean;
  @autoserialize utm: {
    source?: string;
    medium?: string;
    campaign?: string;
    term?: string;
  };
  @autoserialize destination: string;
  @autoserialize destination_url: string;
  @autoserialize description: string;
  @autoserialize sent_at: string;
  @autoserialize start_date: string;
  @autoserialize owner_id: string;
  @autoserialize owner_type: OwnerType;
  @autoserialize short_url: {
    id?: string;
    url?: string;
  };
  @autoserialize slashtag: string;
  @autoserialize zendesk_phone: string;
  @autoserialize area_code: string;
  @autoserialize phone_number: string;

  constructor(payload?: ICampaign) {
    super(payload);
    this.id = payload?.id?.toString();
    this.createdAt = payload?.createdAt;
    this.updatedAt = payload?.updatedAt;
    this.discardedAt = payload?.discardedAt;
    this.name = payload?.name;
    this.state = payload?.state;
    this.utm = payload?.utm;
    this.destination = payload?.destination;
    this.destination_url = payload?.destination_url;
    this.description = payload?.description;
    this.sent_at = payload?.sent_at;
    this.start_date = payload?.start_date;
    this.owner_id = payload?.owner_id;
    this.owner_type = payload?.owner_type;
    this.short_url = payload?.short_url;
    this.qr_code = !!payload?.qr_code;
    this.url = !!payload?.url;
    this.phone = !!payload?.phone;
    this.slashtag = payload?.slashtag;
    this.zendesk_phone = payload?.zendesk_phone;
    this.area_code = payload?.area_code;
    this.phone_number = payload?.phone_number;
  }
}

export interface ICampaign extends IBaseDTO {
  id?: string;
  createdAt?: string | null;
  updatedAt?: string | null;
  discardedAt?: string | null;
  qr_code?: boolean;
  url?: boolean;
  phone?: boolean;
  name?: string | null;
  state?: CampaignState;
  description: string;
  utm?: {
    source?: string;
    medium?: string;
    campaign?: string;
    term?: string;
  };
  sent_at: string;
  start_date: string;
  destination: string;
  destination_url: string;
  short_url: {
    id?: string;
    url?: string;
  };
  slashtag: string;
  zendesk_phone: string;
  area_code: string;
  phone_number: string;
  owner_id: string;
  owner_type: OwnerType;
}
