import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { CampaignsService } from '@rspl-api';
import { EMPTY } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';

import * as fromActions from './campaign.actions';

@Injectable()
export class CampaignEffects {
  createCampaign = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.createCampaign),
      mergeMap((payload) =>
        this.service.create(payload.campaign).pipe(
          map((result) => {
            return fromActions.createCampaignSuccess({
              campaign: result,
            });
          }),
          catchError(() => EMPTY),
        ),
      ),
    );
  });

  updateCampaign = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.updateCampaign),
      mergeMap((payload) => {
        return this.service.update(payload.campaign.id, payload.campaign).pipe(
          map((result) => {
            return fromActions.updateCampaignSuccess({
              campaign: result,
            });
          }),
          catchError(() => [fromActions.updateCampaignFail]),
        );
      }),
    );
  });

  getCampaigns = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.getCampaigns),
      switchMap((params) =>
        this.service.filter(params.findParams).pipe(
          map((results) => {
            return fromActions.getCampaignsSuccess({
              campaigns: results.results,
              resultsCount: results.totalResults,
            });
          }),
          catchError(() => EMPTY),
        ),
      ),
    );
  });

  getCampaign = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.getCampaign),
      switchMap((params) =>
        this.service.find(params.id, (params.expand?.length || 0) > 0, params.expand).pipe(
          map((result) => {
            return fromActions.getCampaignSuccess({
              campaign: result,
            });
          }),
          catchError(() => EMPTY),
        ),
      ),
    );
  });

  deleteCampaign = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.deleteCampaign),
      switchMap((params) =>
        this.service.delete(params.campaignId).pipe(
          map(() => {
            return fromActions.deleteCampaignSuccess({
              campaignId: params.campaignId,
            });
          }),
          catchError(() => EMPTY),
        ),
      ),
    );
  });

  restoreCampaign = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.restoreCampaign),
      switchMap((params) =>
        this.service.restore(params.campaignId).pipe(
          map(() => {
            return fromActions.restoreCampaignSuccess({
              campaignId: params.campaignId,
            });
          }),
          catchError(() => EMPTY),
        ),
      ),
    );
  });

  constructor(
    private actions$: Actions,
    private service: CampaignsService,
  ) {}
}
