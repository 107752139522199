import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BuildType, Campaign, ENVIRONMENT, Environment } from '@domains';
import { Deserialize, Serialize } from 'cerialize';
import { catchError, Observable, of, switchMap } from 'rxjs';

import { BaseApiService } from '../base-api.service';

@Injectable({
  providedIn: 'root',
})
export class CampaignsService extends BaseApiService<Campaign> {
  constructor(
    @Inject(ENVIRONMENT) override config: Environment,
    override http: HttpClient,
  ) {
    super(config, http, 'campaigns', Campaign, 'Campaign');
  }

  override serialize(item: Campaign) {
    return {
      ...Serialize(
        {
          ...item,
        },
        Campaign,
      ),
    };
  }

  override deserialize(data: any): Campaign {
    const res = new Campaign({
      ...Deserialize(data, Campaign),
    });
    res.setInitialValue();
    return res;
  }

  setup(id: string, item: Partial<Campaign>): Observable<Campaign> {
    return this.http.post<Campaign>(`${this.config.urls.baseUrl}/${this.path}/${id}/setup`, {
      qr_code: item.qr_code,
      url: item.url,
      phone: item.phone,
      zendesk_phone: item.zendesk_phone,
      slashtag: item.slashtag,
      area_code: item.area_code,
    });
  }

  override create(item: Campaign): Observable<Campaign> {
    return this.http.post<Campaign>(`${this.config.urls.baseUrl}/${this.path}`, this.serialize(item)).pipe(
      switchMap((createdCampaign) => {
        this.lookupPage = 1;
        this.lookupData = {};
        if (!createdCampaign.id) return of();
        const id = createdCampaign.id;
        return this.setup(id, new Campaign(item)).pipe(
          switchMap(() => {
            this.lookupPage = 1;
            this.lookupData = {};
            return this.find(id);
          }),
          catchError(this.handleError),
        );
      }),
      catchError(this.handleError),
    );
  }

  override update(id: string, item: Campaign): Observable<Campaign> {
    if (!item.isChanged) {
      if (!this.config.production) console.log('No Changes');
      return of(item);
    }
    const changes = this.serialize(item.getChanges());
    if ([BuildType.DEV, BuildType.STAGING].includes(this.config.buildType)) if (!this.config.production) console.log(changes);
    return this.http.put<Campaign>(`${this.config.urls.baseUrl}/${this.path}/${id}`, changes).pipe(
      switchMap(() => {
        this.lookupPage = 1;
        this.lookupData = {};
        return this.setup(id, item).pipe(
          switchMap(() => {
            this.lookupPage = 1;
            this.lookupData = {};
            return this.find(id);
          }),
          catchError(this.handleError),
        );
      }),
      catchError(this.handleError),
    );
  }
}
