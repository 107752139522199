import { Campaign, FindParams } from '@domains';
import { createAction, props } from '@ngrx/store';

export enum CampaignActionType {
  CreateCampaign = '[Campaign] Create Campaign',
  CreateCampaignSuccess = '[Campaign] Create Campaign Success',

  UpdateCampaign = '[Campaign] Update Campaign',
  UpdateCampaignSuccess = '[Campaign] Update Campaign Success',
  UpdateCampaignFail = '[Campaign] Update Campaign Fail',

  ClearCampaignsFindParams = '[Charity] Clear Campaigns Find Params',
  GetCampaigns = '[Campaign] Get Campaigns',
  GetCampaignsSuccess = '[Campaign] Get Campaigns Success',

  GetCampaign = '[Campaign] Get Campaign',
  GetCampaignSuccess = '[Campaign] Get Campaign Success',

  DeleteCampaign = '[Campaign] Delete Campaign',
  DeleteCampaignSuccess = '[Campaign] Delete Campaign Success',

  RestoreCampaign = '[Campaign] Restore Campaign',
  RestoreCampaignSuccess = '[Campaign] Restore Campaign Success',
}

export const createCampaign = createAction(CampaignActionType.CreateCampaign, props<{ campaign: Campaign }>());
export const createCampaignSuccess = createAction(CampaignActionType.CreateCampaignSuccess, props<{ campaign: Campaign }>());

export const updateCampaign = createAction(CampaignActionType.UpdateCampaign, props<{ campaign: Campaign }>());
export const updateCampaignSuccess = createAction(CampaignActionType.UpdateCampaignSuccess, props<{ campaign: Campaign }>());
export const updateCampaignFail = createAction(CampaignActionType.UpdateCampaignFail, props<{ campaign: Campaign }>());

export const clearCampaignsFindParams = createAction(CampaignActionType.ClearCampaignsFindParams);
export const getCampaigns = createAction(CampaignActionType.GetCampaigns, props<{ findParams: FindParams; keep?: boolean }>());
export const getCampaignsSuccess = createAction(
  CampaignActionType.GetCampaignsSuccess,
  props<{ campaigns: Array<Campaign>; resultsCount: number }>(),
);

export const getCampaign = createAction(CampaignActionType.GetCampaign, props<{ id: string; keep?: boolean; expand?: string[] }>());
export const getCampaignSuccess = createAction(CampaignActionType.GetCampaignSuccess, props<{ campaign: Campaign }>());

export const deleteCampaign = createAction(CampaignActionType.DeleteCampaign, props<{ campaignId: string }>());
export const deleteCampaignSuccess = createAction(CampaignActionType.DeleteCampaignSuccess, props<{ campaignId: string }>());

export const restoreCampaign = createAction(CampaignActionType.RestoreCampaign, props<{ campaignId: string }>());
export const restoreCampaignSuccess = createAction(CampaignActionType.RestoreCampaignSuccess, props<{ campaignId: string }>());
