import { createFeatureSelector, createSelector } from '@ngrx/store';

import { CampaignState } from './campaign.reducers';

// tslint:disable: max-line-length
export const campaignsFeatureStateSelector = createFeatureSelector<CampaignState>('campaign');

export const selectCampaigns = createSelector(campaignsFeatureStateSelector, (state: CampaignState) => state.campaigns);
export const selectCampaignsTotalCount = createSelector(campaignsFeatureStateSelector, (state: CampaignState) => state.campaignsTotalCount);

export const selectCampaign = createSelector(campaignsFeatureStateSelector, (state: CampaignState) => state.campaign);
