import { Routes } from '@angular/router';
import { HistoryPageComponent, UiTemplateComponent } from '@core';
import { Environment, Role } from '@domains';
import { AuthGuard, RoleGuard } from '@rspl-auth';
import { HealthCheckComponent } from '@rspl-ui';

export const appRoutes = (environment: Environment) =>
  [
    {
      path: '',
      component: UiTemplateComponent,
      canActivate: [AuthGuard, RoleGuard],
      data: { roles: [Role.CAPTAIN] },
      children: [
        {
          path: '',
          loadChildren: () => import('@donations').then((m) => m.donationsRoutes(environment)),
        },
        {
          path: 'charities',
          loadChildren: () => import('@charities').then((module) => module.charitiesRoutes(environment)),
        },
        {
          path: 'users',
          loadChildren: () => import('@users').then((module) => module.usersRoutes(environment)),
        },
        {
          path: 'partners',
          loadChildren: () => import('@partners').then((module) => module.partnersRoutes(environment)),
        },
        {
          path: 'markets',
          loadChildren: () => import('@markets').then((module) => module.marketsRoutes(environment)),
        },
        {
          path: 'xmiles',
          loadChildren: () => import('@xmiles').then((module) => module.xmilesRoutes(environment)),
        },
        {
          path: 'public-reviews',
          loadComponent: () => import('@public-reviews').then((module) => module.PublicReviewsComponent),
          canActivate: [AuthGuard, RoleGuard],
          data: {
            roles: [Role.CAPTAIN],
          },
        },
        {
          path: 'notifications',
          loadChildren: () => import('@notifications').then((module) => module.notificationsRoutes(environment)),
        },
        {
          path: 'organizations',
          loadChildren: () => import('@organizations').then((module) => module.organizationsRoutes(environment)),
        },
        {
          path: 'journeys',
          loadChildren: () => import('@journeys').then((module) => module.journeysRoutes(environment)),
        },
        {
          path: 'items',
          loadChildren: () => import('@items').then((module) => module.itemsRoutes(environment)),
        },
        {
          path: 'features',
          loadChildren: () => import('@features').then((module) => module.featuresRoutes(environment)),
        },
        {
          path: 'trp',
          loadComponent: () => import('@trp').then((module) => module.TrpMapComponent),
          canActivate: [AuthGuard, RoleGuard],
          data: {
            roles: [Role.CAPTAIN],
          },
        },
        {
          path: 'phone-configurations',
          loadChildren: () => import('@phone-configurations').then((module) => module.phoneConfigurationRoutes(environment)),
        },
        {
          path: 'playback',
          loadComponent: () => import('@playback').then((module) => module.PlaybackComponent),
          canActivate: [AuthGuard, RoleGuard],
          data: {
            roles: [Role.CAPTAIN],
          },
        },
        {
          path: 'concierge',
          loadChildren: () => import('@anomalies').then((m) => m.anomaliesRoutes(environment)),
        },
        {
          path: 'campaigns',
          loadChildren: () => import('@campaigns').then((m) => m.campaignsRoutes(environment)),
        },
        {
          path: 'history',
          component: HistoryPageComponent,
        },
      ],
    },
    {
      path: 'hc',
      component: HealthCheckComponent,
    },
    {
      path: '**',
      redirectTo: '/',
    },
  ] as Routes;
