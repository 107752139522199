import { HttpClient } from '@angular/common/http';
import { Inject } from '@angular/core';
import { BaseDTO, BuildType, ENVIRONMENT, Environment, HistoryEvent } from '@domains';
import { Deserialize, Serialize } from 'cerialize';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map, switchMap, take } from 'rxjs/operators';

export abstract class BaseApiService<T extends BaseDTO> {
  private lookupText?: string | null;
  private lookupExpand: string[] = [];
  protected lookupPage = 1;
  private lookupFilters: { [key: string]: any } = {};
  protected lookupData: {
    results?: T[];
    totalResults?: number;
    loading?: boolean;
  } = {};
  private lookupSubject = new BehaviorSubject<{
    results?: T[];
    totalResults?: number;
    loading?: boolean;
  }>(this.lookupData);
  lookupItems$ = this.lookupSubject.asObservable();

  constructor(
    @Inject(ENVIRONMENT) protected config: Environment,
    protected http: HttpClient,
    protected path: string,
    protected type: new (p?: any) => T,
    protected historyTargetType?: string,
    protected expandFilter: string[] = [],
    protected expandFind: string[] = [],
  ) {}

  serialize(item: T | Record<string, never>): any {
    return Serialize(item, this.type);
  }

  deserialize(data: any): T {
    const res = new this.type(Deserialize(data, this.type));
    res.setInitialValue();
    return res;
  }

  handleError = (error: any) => {
    console.error(error);
    throw error;
  };

  lookup(
    text: string | null | undefined = '',
    page = 1,
    expand: string[] = [],
    filters: { [key: string]: any } = {},
    itemsPerPage?: number,
  ) {
    if (
      this.lookupText === text &&
      JSON.stringify(this.lookupExpand) === JSON.stringify(expand) &&
      JSON.stringify(filters) === JSON.stringify(this.lookupFilters) &&
      (page == this.lookupPage || this.lookupData.totalResults === this.lookupData.results?.length)
    ) {
      return;
    }
    this.lookupSubject.next({
      ...(page === this.lookupPage || text !== this.lookupText
        ? {
            results: [],
            totalResults: 0,
          }
        : this.lookupData),
      loading: true,
    });
    this.lookupFilters = filters;
    this.lookupText = text;
    this.lookupExpand = expand;
    this.lookupPage = page;
    this.http
      .get(`${this.config.urls.baseUrl}/${this.path}`, {
        params: {
          ...(expand.length > 0 ? { 'expand[]': expand } : {}),
          ...filters,
          ...(!!text && (text?.trim().length || 0) > 0 ? { name_match: text } : {}),
          page,
          per_page: itemsPerPage || ([BuildType.DEV, BuildType.STAGING].includes(this.config.buildType) ? 10 : 50),
          order: 'name',
          order_direction: 'asc',
        },
        observe: 'response',
      })
      .pipe(
        map((response: any) => {
          return {
            results: response.body?.map((x: any) => this.deserialize(x)),
            totalResults: Number(response.headers.get('Total-Count')),
          };
        }),
        catchError(this.handleError),
        take(1),
      )
      .subscribe((res) => {
        if (this.lookupPage === 1) {
          this.lookupData = res;
        } else {
          this.lookupData = {
            ...this.lookupData,
            results: [...(this.lookupData.results || []), ...res.results],
            loading: false,
          };
        }
        this.lookupSubject.next(this.lookupData);
      });
  }

  filter(params?: { [param: string]: any }): Observable<{
    results: Array<T>;
    totalResults: number;
  }> {
    return this.http
      .get(`${this.config.urls.baseUrl}/${this.path}`, {
        params: {
          ...(this.expandFilter.length > 0 ? { 'expand[]': this.expandFilter } : {}),
          ...params,
        },
        observe: 'response',
      })
      .pipe(
        map((response: any) => {
          return {
            results: response.body?.map((x: any) => this.deserialize(x)),
            totalResults: Number(response.headers.get('Total-Count')),
          };
        }),
        catchError(this.handleError),
      );
  }

  find(id: string, expand = true, expandList?: string[]): Observable<T> {
    return this.http
      .get<T>(`${this.config.urls.baseUrl}/${this.path}/${id}`, {
        params: {
          ...(expand && (expandList || this.expandFind).length > 0 ? { 'expand[]': expandList || this.expandFind } : {}),
        },
      })
      .pipe(
        map((res: any) => {
          return this.deserialize(res);
        }),
        catchError(this.handleError),
      );
  }

  create(item: T | Record<string, never>): Observable<T> {
    return this.http.post<T>(`${this.config.urls.baseUrl}/${this.path}`, this.serialize(item)).pipe(
      map((response: T) => {
        this.lookupPage = 1;
        this.lookupData = {};
        return this.deserialize(response);
      }),
      catchError(this.handleError),
    );
  }

  update(id: string, item: T): Observable<T> {
    if (!item.isChanged) {
      if (!this.config.production) console.log('No Changes');
      return of(item);
    }
    const changes = this.serialize(item.getChanges());
    if ([BuildType.DEV, BuildType.STAGING].includes(this.config.buildType)) if (!this.config.production) console.log(changes);
    return this.http.put<T>(`${this.config.urls.baseUrl}/${this.path}/${id}`, changes).pipe(
      switchMap(() => {
        this.lookupPage = 1;
        this.lookupData = {};
        return this.find(id);
      }),
      catchError(this.handleError),
    );
  }

  delete(id: string): Observable<any> {
    return this.http.delete<T>(`${this.config.urls.baseUrl}/${this.path}/${id}`).pipe(catchError(this.handleError));
  }

  restore(id: string): Observable<any> {
    return this.http.post<T>(`${this.config.urls.baseUrl}/${this.path}/${id}/restore`, {}).pipe(catchError(this.handleError));
  }

  history(ids: string[]): Observable<Array<HistoryEvent>> {
    if (!this.historyTargetType) return of();
    return this.http
      .get<HistoryEvent[]>(this.config.urls.baseUrl + '/events', {
        params: {
          expand: 'user',
          target_type: this.historyTargetType,
          'target_id[]': ids,
          order: 'created_at',
          order_direction: 'desc',
        },
      })
      .pipe(
        map((response: any) => {
          return response?.map((r: any) => new HistoryEvent(Deserialize(r, HistoryEvent)));
        }),
        catchError(this.handleError),
      );
  }
}
