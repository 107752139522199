import { Campaign } from '@domains';
import { Action, createReducer, on } from '@ngrx/store';

import * as fromActions from './campaign.actions';

export interface CampaignState {
  campaigns: Array<Campaign>;
  campaignsTotalCount: number;
  campaign: Campaign;
}

const initialState: CampaignState = {
  campaigns: new Array<Campaign>(),
  campaignsTotalCount: 0,
  campaign: new Campaign(),
};

const reducer = createReducer(
  initialState,

  on(fromActions.createCampaignSuccess, (state, action: any) => ({ ...state, campaign: action.campaign })),
  on(fromActions.updateCampaignSuccess, (state, action: any) => ({ ...state, campaign: action.campaign })),

  on(fromActions.getCampaigns, (state, { keep }) => {
    return { ...state, campaigns: keep ? state.campaigns : [] };
  }),
  on(fromActions.getCampaignsSuccess, (state, { campaigns, resultsCount }) => ({ ...state, campaigns, campaignsTotalCount: resultsCount })),
  on(fromActions.clearCampaignsFindParams, (state) => {
    return { ...state };
  }),

  on(fromActions.getCampaign, (state, { keep }) => ({ ...state, campaign: keep ? state.campaign : null })),
  on(fromActions.getCampaignSuccess, (state, action: any) => ({ ...state, campaign: action.campaign })),
);

export function campaignReducer(state: CampaignState | undefined, action: Action) {
  return reducer(state, action);
}
